const pages = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: '',
  },
  {
    title: 'Files',
    path: '/filemanager/list',
    icon: '',
  },
  {
    title: 'Labelling',
    path: '/labelling',
    icon: '',
  },
  {
    title: 'Trainings',
    path: '/customer/trainings',
    icon: '',
  },
  {
    title: 'Testings',
    path: '/customer/testings',
    icon: '',
  },
  {
    title: 'Companies',
    path: '/admin/companies',
    icon: '',
  },
  {
    title: 'Users',
    path: '/admin/users',
    icon: '',
  },
  {
    title: 'Users',
    path: '/customer/users',
    icon: '',
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: '',
  },
];

export default pages;
